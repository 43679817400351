<template>
    <div class="flex flex-col gap-2 py-1.5 text-sm font-medium pl-4">
        <nuxt-link to="/developers/user-guide/introduction/welcome"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/user-guide`) && !route.path.includes('/developers/user-guide/development/get-help') && !route.path.includes('/developers/user-guide/connect') }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:cpu-chip-16-solid" />
            </div>
            Live Data Framework
        </nuxt-link>
        <nuxt-link to="/developers/ai-pipelines/welcome"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/ai-pipelines`) }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:document-magnifying-glass" />
            </div>
            AI Pipelines
        </nuxt-link>
        <nuxt-link to="/developers/user-guide/connect/pathway-connectors"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/user-guide/connect`) }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:arrows-right-left-16-solid" />
            </div>
            Connectors
        </nuxt-link>
        <nuxt-link to="/developers/api-docs/pathway"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/api-docs`) }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:code-bracket-16-solid" />
            </div>
            API Docs
        </nuxt-link>
        <nuxt-link to="/developers/templates"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/templates`) }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:bolt-solid" />
            </div>
            App Templates
        </nuxt-link>
        <nuxt-link to="/developers/user-guide/development/get-help"
            class="bg-gray-100 p-1 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500"
            :class="{ 'active': $route.path.includes(`/developers/user-guide/development/get-help`) }">
            <div class="p-2 bg-gray-200 rounded-lg font-base group-hover:bg-primary-100 transition">
                <Icon name="heroicons:lifebuoy-solid" />
            </div>
            Get help
        </nuxt-link>
    </div>
    <div class="ml-4 py-2">
        <span class="text-sm font-bold">{{ currentNavTitle }}</span>
    </div>
</template>

<script setup>
const mapUrlNames = {
    "/developers/user-guide": "Live Data Framework",
    "/developers/ai-pipelines": "AI Pipelines",
    "/developers/templates": "App Templates",
    "/developers/tutorials": "Tutorials",
    "/developers/api-docs": "API Docs"
}
const currentNavTitle = ref("")
const route = useRoute()
const getCurrentNavTitle = () => {
    return mapUrlNames[route.path.split("/").slice(0, 3).join("/")]
}
onMounted(() => {
    currentNavTitle.value = getCurrentNavTitle()
})
</script>

<style scoped lang="scss">
.active {
    color: var(--colors-primary-500);

    & div {
        background-color: var(--colors-primary-100);
    }
}
</style>
