<script setup lang="ts">
const route = useRoute()
const { tree } = useCurrentNavigation()
const { page } = useContent()

/* If tutorials */
const tagDictionary = {}

const flatTree = ref([])

/* Flat items */
tree.value.forEach(item => {
    if (item.children) {
        flatTree.value.push(...item.children)
    } else {
        flatTree.value.push(item)
    }
})

flatTree.value.forEach(item => {
    if (item.article && item.article.tags) {
        item.article.tags.forEach(tag => {
            if (tag !== "tutorial" && tag !== "showcase") {


                if ((item.article.tags.includes("tutorial") || item.article.tags.includes("showcase"))) {
                    if (tag !== item.article.tags[1]) {
                        return;
                    }
                } else {
                    if (tag !== item.article.tags[0]) {
                        return;
                    }
                }
                if (!tagDictionary[tag]) {
                    tagDictionary[tag] = {
                        id: tag,
                        title: tag.length <= 3 ? tag.toUpperCase() : tag === "ai-pipelines" ? "AI Pipelines" : tag.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),
                        _path: item._path.includes("tutorials") ? `/developers/tutorials?tag=${tag}` : `/developers/templates?tag=${tag}`,
                        children: [],
                    };
                }
                tagDictionary[tag].children.push({
                    title: item.title,
                    _path: item._path,
                    article: item.article,
                })
            }

        })

    }
})
/* ORDER NAVIGATION */
const result = getTemplatesOrder(Object.values(tagDictionary))

</script>

<template>
    <nav>
        <DocsDevNavHeader />
        <DocsAsideTree v-if="result?.length > 0" :links="result" />
        <NuxtLink v-else to="/" class="flex items-center text-sm cursor-pointer u-text-gray-500 hover:u-text-gray-700">
            <Icon name="heroicons-outline:arrow-left" class="w-4 h-4" />
            <span class="ml-2">Go back</span>
        </NuxtLink>
    </nav>
</template>
