<script setup lang="ts">
const { page, navigation } = useContent()


// const isDocs = computed(() => page.value?._path.includes("developers/api-docs"))
const isServer = computed(() => page.value?.server)
// const isTemplate = computed()
const article = computed(() => page.value?.article)

const articleType = computed(() => {
    if (!page.value?.article) {
        return "default"
    }

    if (page.value?.article?.type) {
        return page.value.article.type
    }

    const articleTypes = {
        "developers/templates": "template"
    }

    if (page.value?._path) {
        for (const [key, value] of Object.entries(articleTypes)) {
            if (page.value._path.includes(key)) {
                return value
            }
        }
    }

    return "default"
})

const hasAside = computed(() => page.value?.aside !== false && navigation.value?.length > 1)
const hasComments = computed(() => page.value?.comments)
const hasToc = computed(() => page.value?.toc !== false && page.value?.body?.toc?.links?.length >= 1)
// const hasNav = computed(()=> page.value?.hasNav !== false)
const hasBody = computed(() => !page.value || page.value?.body?.children?.length > 0)
const hasFooter = computed(() => page.value?.footer !== false)
const ctaMobile = computed(() => page.value?.ctaMobile)
const related = await getRelatedArticles(page.value) ?? []
const currentUrl = computed(() => process.client ? window.location.href : 'https://pathway.com' + page.value?._path)

const isSingle = computed(() => {
    if(page.value?.hasNav){
        return false
    }
    if (page.value?.single) {
        return page.value?.single
    }
    if (related.length < 0) {
        return true
    }
    if ((!article.value?.related || !article.value?.article?.related) && !hasToc.value) {
        return true
    }

    return false
})

/* ToC mobile logic */
const isOpen = ref(false)

/* Top buttons logic */
const buttons = computed(() => {
    const result = {}
    if (page.value?.notebook_export_path) {
        result.notebook = "https://github.com/pathwaycom/pathway/blob/main/examples/" + page.value.notebook_export_path
        result.colab = "https://colab.research.google.com/github/pathwaycom/pathway/blob/main/examples/" + page.value.notebook_export_path
        result.studio = "https://lightning.ai/new?repo_url=" + result.notebook
    }
    if (page.value?.notebook_github_link) {
        result.notebook = "https://github.com/pathwaycom/pathway/blob/main/examples/" + page.value.notebook_github_link
        result.colab = "https://colab.research.google.com/github/pathwaycom/pathway/blob/main/examples/" + page.value.notebook_github_link
        result.studio = "https://lightning.ai/new?repo_url=" + result.notebook
    }
    if (page.value?.project_github_link) {
        result.project = page.value?.project_github_link
    }
    if (page.value?.docker_github_link) {
        result.docker = page.value?.docker_github_link
    }

    return result
})

const articleTags = article.value?.tags?.join(' · ')?.toUpperCase() ?? ''

const articleDate = new Date(article.value?.date)?.toLocaleString('en-EN', {
    month: 'long', day: 'numeric',
    year: 'numeric'
}) ?? ''

const articleContent = ref(null)
function calcReadingTime () {
    const wordsPerMinute = 240
    const noOfWords = articleContent.value?.textContent?.split(/\s/g)?.length || 0
    const minutes = noOfWords / wordsPerMinute
    const readTime = Math.ceil(minutes)
    return readTime
}

function getTitleId (title) {
    return title.toLowerCase().replace(/[^a-z0-9\s-]/g, '').trim().replace(/\s+/g, '-').replace(/-+/g, '-')
}

if (!isSingle.value) {
    if (related.length < 0) {
        isSingle.value = true
    }
    if (article.value?.related) {
        isSingle.value = true
    }
    if (article.value?.article?.related) {
        isSingle.value = true
    }
}

const bread = [{
    label: 'Home',
    icon: 'heroicons-outline:home',
    to: '/'
}, {
    label: 'Glossary',
    icon: 'heroicons:bookmark-square-16-solid'
}]

</script>

<template>
    <div class="document-driven-page-overlay pb-8 min-h-screen">
        <div class="flex bg-[#F2F2F2] max-w-[80rem] lg:pb-4 lg:px-0 md:px-2 rounded-lg mx-auto w-full relative">
            <aside v-if="hasAside"
                class="docsaside lg:top-header hidden overflow-y-auto overflow-x-hidden pb-8 lg:sticky lg:block lg:max-h-[calc(100vh-var(--header-height))] lg:self-start pt-4 ">
                <DocsAside />
            </aside>

            <!-- MAIN -->
            <main class="mainpagecontent w-full mr-1 p-8 related" v-if="hasBody" :class="{
                'md:max-w-[53rem] max-w-full': (!isSingle && hasToc && hasAside),
                'max-w-full': !hasAside,
                'single': isSingle || !hasAside
            }">
                <UBreadcrumb :links="bread" class="mb-5" v-if="page?._path?.includes('/glossary/')">
                    <template #divider>
                        <Icon name="octicon:chevron-right-24" />
                        <!-- <span class="w-8 h-1 rounded-full bg-gray-300 dark:bg-gray-700" /> -->
                    </template>
                </UBreadcrumb>
                <!-- TOC:MOBILE -->
                <div v-if="hasToc && !ctaMobile" :class="{ 'flex items-center lg:block': !isOpen }"
                    class="lg:hidden sticky top-header flex items-center toc overflow-y-auto overflow-x-hidden z-10">
                    <div class="w-full  cursor-pointer" @click="isOpen = !isOpen">
                        <button class="flex items-center gap-1 py-3 lg:hidden px-2">
                            <span class="text-xs font-semibold">Table of Contents</span>
                            <Icon name="heroicons-outline:chevron-right"
                                class="w-4 h-4 transition-transform duration-100 transform"
                                :class="[isOpen ? 'rotate-90' : 'rotate-0']" />
                        </button>
                        <DocsToc class="mb-4" :class="[isOpen ? 'lg:block' : 'hidden lg:block']"
                            @move="isOpen = false" />
                    </div>
                </div>
                <div v-if="ctaMobile" class="lg:hidden sticky top-header flex items-center z-10 px-4 py-2 flex justify-end items-center w-screen -ml-8 bg-gray-100 border-b border-gray-200">
                    <nuxt-link :to="ctaMobile.href" class="block block max-w-max bg-secondary-500 px-3.5 py-2 rounded-lg border-2 border-[#dc280b] transition text-white text-sm">{{ ctaMobile.text }}</nuxt-link>
                </div>

                <!-- ARTICLE -->
                <article v-if="article" class="content md:max-w-2xl max-w-full mx-auto">
                    <div class="flex justify-between items-center flex-wrap">
                        <nav class="text-sm text-gray-400">
                            <ul class="breadcrumb flex-wrap" aria-label="Breadcrumb">
                                <li class="breadcrumb-item"
                                    v-for="(breadcrumb, i) in $route.path.split('/').slice(1, -1)">
                                    <!-- <NuxtLink :to="`/${$route.path.split('/').slice(1, i + 2).join('/')}` ?? '/'">
                                        {{ breadcrumb[0].toUpperCase() + breadcrumb.slice(1) }}
                                    </NuxtLink> -->
                                    {{ breadcrumb[0].toUpperCase() + breadcrumb.slice(1) }}
                                </li>
                                <li>
                                    <NuxtLink :to="currentUrl" class="text-gray-700 hover:underline">
                                        {{ page.title.length < 10 ? page.title : page.title.split(' ')[0] + '... ' + page.title.split(' ')[page.title.split(' ').length - 1] }} </NuxtLink>
                                </li>
                            </ul>
                            <!-- <nav aria-label="Breadcrumb">
                                <ol class="breadcrumb">
                                    <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
                                        <nuxt-link v-if="index !== breadcrumbs.length - 1" :to="crumb.path">{{
                crumb.title }}</nuxt-link>
                                        <span v-else>{{ crumb.title }}</span>
                                    </li>
                                </ol>
                            </nav> -->
                        </nav>
                    </div>
                    <ArticleTop :article="article" :page="page" :buttons="buttons" :deployButtons="page?.deployButtons"/>

                    <!-- Article Heading -->
                    <div class="mb-8">
                        <ProseH1 :id="getTitleId(page.title)">{{ page.title }}</ProseH1>
                                            <div class="flex justify-between items-center flex-wrap">
                                                <div class="flex gap-2 items-center text-sm flex-wrap">
                                                    <div>
                                                        <img :src="page.author.img"
                                                            class="w-5 h-5 object-cover rounded-full" />
                                                    </div>
                                                    <span class="font-semibold">{{ page.author.name }}</span>
                                                    <div v-if="page.author?.coauthors" class="inline-flex gap-2">
                                                        <span class="text-gray-400">·</span>
                                                        <img :src="page.author.coauthors.img"
                                                        class="w-5 h-5 object-cover rounded-full" />
                                                        <span class="font-semibold" >{{ page.author.coauthors.name }}</span>
                                                    </div>
                                                    <span class="text-gray-400">·</span>
                                                    <span class="text-gray-400">{{ articleDate }}</span>
                                                    <span class="text-gray-400">·</span>
                                                    <span class="text-gray-400">{{ calcReadingTime() }} min read</span>
                                                </div>
                                                <div class="md:block hidden">
                                                    <ArticleShare :href="currentUrl" v-if="article" :pure="true" />
                                                </div>
                                            </div>

                    </div>
                    <hr class="mt-4" />

                    <div class="text-pretty article overflow-auto" ref="articleContent">
                        <StaticMarkdownRender :path="page?._path" v-if="isServer" />
                        <slot v-else />
                    </div>
                </article>

                <article v-else class="content">

                    <div v-if="page?.notebook_export_path || page?.notebook_github_link" class="mb-8 flex gap-4">
                        <ButtonBadge type="notebook" :buttons="buttons" />
                    </div>
                    <GoBackToArticle v-if="page?._path?.includes(' glossary/')" />
                                        <StaticMarkdownRender :path="page?._path" v-if="isServer" />
                                        <slot v-else class="text-pretty" />
                </article>

                <ArticleFooter :page="page" :article="article" :related="related" :articleType="articleType"
                    :currentUrl="currentUrl" :isSingle="isSingle" :hasComments="hasComments" v-if="hasFooter"/>
                <!-- ARTICLE END -->
            </main>
            <main v-else>
                <div class="w-full h-full flex justify-center align-center bg-white">
                    <slot />
                </div>
            </main>
            <aside
                class="toc toc-desktop w-[15rem] hidden lg:block sticky overflow-y-auto items-center px-4 -mx-4 lg:max-h-[99vh] lg:pb-16 top-header pt-2 lg:self-start lg:mx-0 lg:self-start lg:px-0 lg:pt-2 lg:mt-0"
                v-if="hasToc || related?.length">
                <DocsToc class="mb-4 lg:mt-0 lg:p-5" :class="[isOpen ? 'lg:block' : 'hidden lg:block']"
                    @move="isOpen = false" v-if="hasToc" />
                <DocsRelated :related="related" v-if="related?.length" class="p-5" :class="{ 'pt-0': hasToc }" />
            </aside>
        </div>

    </div>
</template>


<style scoped>
.document-driven-page-overlay {
    background-color: #E5E5E5;
    padding-top: 2em;
}

.mainpagecontent {
    background-color: #fff;
    border-radius: 0.5em;
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
}


.docsaside {
    min-width: 15em;
    max-width: 15em;
    padding-right: 1em;
}

::v-deep(.article h1) {
    display: none;
}
</style>

<style lang="postcss">
article:deep(div:first-child h1:first-child) {
    @apply mt-0 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl text-red-500;
}

.content div:first-child h1:first-child {
    @apply mt-0 text-2xl font-extrabold tracking-tight sm:text-3xl;
}

article:deep(div:first-child h1:first-child + p) {
    @apply mt-0 mb-8 sm:text-lg text-gray-500 pb-8 border-b border-gray-100;

    & a {
        @apply text-gray-700 hover:border-gray-700;
    }
}

.toc {
    &:before {
        content: " ";
        width: 100%;
        right: 0;
        @apply absolute top-0 z-[-1] block h-full bg-white/95 backdrop-blur dark:bg-black/95;
    }
}

@media (max-width: 1250px) {
    .mainpagecontent.single {
        max-width: 100%;
    }

    .mainpagecontent {
        max-width: clamp(30rem, 60vw, 50rem);
    }
}

@media (max-width: 1023px) {
    .mainpagecontent {
        max-width: 100%;
    }
}

@screen lg {
    .toc {
        &:before {
            display: none;
        }
    }
}

.breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
}

.breadcrumb-item {
    margin-right: 5px;
}

.breadcrumb-item:hover a {
    text-decoration: underline;
}

.breadcrumb-item::after {
    content: '/';
    margin-left: 5px;
}

.breadcrumb-item:last-child::after {
    content: '';
}
</style>
